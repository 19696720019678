
import { Component, Vue } from 'vue-property-decorator'
import InputSearch from '@/components/InputSearch.vue'
import XBox from '@/components/XBox.vue'
import MContract from './MContract.vue'
import { parseTime, tardeFilter } from '@/utils/filters'
import {
  getTradePage,
  getTradeTypeList,
  getActiveContract,
  ContractInfo,
  TradeInfo,
  TradeQueryPage,
} from '@/api/contract'
import type { IOption, IPagination } from '@/utils/interface'

@Component({
  components: { XBox, InputSearch, MContract },
  name: 'Own',
  filters: { parseTime, tardeFilter },
})
export default class extends Vue {
  listLoading = true

  /** 分页器 */
  pagination: IPagination<TradeInfo> = {
    total: 0,
    pageNum: 1,
    pageSize: 10,
    list: [],
  }

  /** 资产信息 */
  ownInfo: ContractInfo = {
    contractId: '',
    freeTdou: 0,
    preDueDate: '',
  }

  /** 表单数据 */
  formData: Pick<TradeQueryPage, 'tradeType' | 'tradeDate'> = {
    tradeType: '',
    tradeDate: ['', ''],
  }

  /** 交易类型 */
  tradeTypeList: IOption[] = []

  /** 获取资产信息 */
  async getActiveContract() {
    this.ownInfo = (await getActiveContract()) || {}
  }

  /** 获取交易类型数据 */
  async getTradeTypeList() {
    this.tradeTypeList = await getTradeTypeList()
  }

  async getList() {
    this.listLoading = true
    const { tradeType } = this.formData
    const tradeDate = this.formData.tradeDate || []
    const { pageNum, pageSize } = this.pagination
    const { recordList, totalCount } = await getTradePage({
      tradeType,
      tradeStartDate: tradeDate[0],
      tradeEndDate: tradeDate[1],
      pageNum,
      pageSize,
    })
    this.pagination.list = recordList
    this.pagination.total = totalCount
    this.listLoading = false
  }

  /** 新增 */
  onAdd() {
    console.log('click add')
  }

  filterHandler(value: any, row: any, column: any) {
    const property = column.property
    return row[property] === value
  }

  created() {
    this.getActiveContract()
    this.getTradeTypeList()
    this.getList()
  }
}
