
import { Component, Prop, Vue } from 'vue-property-decorator'
import XBox from '@/components/XBox.vue'
import { getActiveContract, ContractInfo } from '@/api/contract'
import { formatDate } from '@panstar/common'

/** 我的资产 */
@Component({
  components: { XBox },
  name: 'Contract',
})
export default class extends Vue {
  /** 是否显示更多 */
  @Prop({ default: false }) hasMore!: Boolean
  /** 是否显示更多 */
  @Prop({ default: 'normal' }) size!: 'normal' | 'small'

  /** 更多文本 */
  get moreText() {
    return this.hasMore ? '更多' : ''
  }

  /** 我的资产 */
  ownInfo: ContractInfo = {
    contractId: '',
    freeTdou: 0,
    preDueDate: '',
  }

  get dueDate() {
    return formatDate(this.ownInfo.preDueDate, 'yyyy年MM月dd日')
  }

  /** 跳转到资产页面 */
  onClickMore() {
    this.$router.push({ name: 'own' })
  }

  async created() {
    /** 获取资产信息 */
    this.ownInfo = (await getActiveContract()) || {}
  }
}
