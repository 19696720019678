import { formatStringEllipsis, formatDate } from '@panstar/common'
import type { IOption } from './interface'

/** 格式化时间 */
export const parseTime = (timestamp: string, fmt: string) => {
  // return timestamp && new Date(timestamp).toLocaleString()
  return timestamp && formatDate(timestamp, fmt)
}

/** 手机号加密 */
export const phoneFilter = (val: string) => {
  return val && formatStringEllipsis(String(val), 3, 4, { replaceStr: '****' })
}

const statusMap: { [key: string]: string } = {
  0: '启用',
  1: '禁用',
}
/** 启用/禁用 */
export const statusFilter = (status: string) => {
  return statusMap[status] || '未知'
}

const statusTypeMap: { [key: string]: string } = {
  0: 'success',
  1: 'gray',
}
/** 启用/禁用 tag颜色 */
export const statusTypeFilter = (status: string) => {
  return statusTypeMap[status]
}
export const progressMap: { [key: string]: string } = {
  0: '待授权',
  1: '调查中',
  2: '已完成',
}
/** 背调进度 */
export const progressFilter = (status: string) => {
  return progressMap[status]
}
const progressTypeMap: { [key: string]: string } = {
  0: 'waring',
  1: 'success',
  2: 'gray',
}
/** 启用/禁用 tag颜色 */
export const progressTypeFilter = (status: string) => {
  return progressTypeMap[status]
}

/** 交易类型 */
export const tardeMap: { [key: string]: string } = {
  '00': '充值',
  '01': '消费',
  '02': '退款',
}
/** 交易类型，00：充值，01：消费，02：退款 */
export const tardeFilter = (status: string) => {
  return tardeMap[status]
}
/** 背调结果，00:无风险，01:低风险，02：中风险，03：高风险 */
export type TCheckResultMap = '00' | '01' | '02' | '03'
/** 背调结果 */
export const checkResultMap: { [key: string]: string } = {
  '00': '无风险',
  '01': '低风险',
  '02': '中风险',
  '03': '高风险',
}
/** 背调结果 */
export const checkResultFilter = (status: TCheckResultMap) => {
  return checkResultMap[status]
}

/** 订单状态，00：待授权、01：授权中、02：预审中、03：调查中、04：质检中、05：质检退回、06：已完成（非终版）、07：已完成（终版）、08：再版中、99：已取消 */
export type TOrderStatusMap = '00' | '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '99'
/** 订单状态 */
export const orderStatusMap: { [key: string]: string } = {
  '00': '待授权',
  '01': '授权中',
  '02': '预审中',
  '03': '调查中',
  '04': '质检中',
  '05': '质检退回',
  '06': '已完成（非终版)',
  '07': '已完成（终版）',
  '08': '再版中',
  '99': '已取消',
}
/** 订单状态 */
export const orderStatusFilter = (status: TOrderStatusMap) => {
  return orderStatusMap[status]
}
/** 国内学历类型：00：最高学历，01：本科，02：硕士，03：博士，04：大专，05：高中，06:中专 */
export type TAcadType = '00' | '01' | '02' | '03' | '04' | '05' | '06'
/** 国内学历类型 */
export const acadTypeMap: { [key: string]: string } = {
  '00': '最高学历',
  '01': '本科',
  '02': '硕士',
  '03': '博士',
  '04': '大专',
  // '05': '高中',
  // '06': '中专',
}
/** 国内学位类型：00：最高学位，01：学士，02：硕士，03：博士 */
export type TDegreeType = '00' | '01' | '02' | '03'
/** 国内学位类型 */
export const degreeTypeMap: { [key: string]: string } = {
  '00': '最高学历',
  '01': '学士',
  '02': '硕士',
  '03': '博士',
}

/** 背调开展类型 */
export const checkTypeMap: { [key: string]: string } = {
  '00': '可以立即开展',
  '01': '在指定日期后开展',
}

/** 根据map转化成option数组的类型 */
export const getOptions = (map: Record<number, string>) =>
  Object.entries(map).map(([key, val]) => ({ value: key, text: val } as IOption))
